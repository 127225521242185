import React from "react"

// stack
import react from "../../images/react.png"
import node from "../../images/node.png"
import ts from "../../images/ts.png"
import vite from "../../images/vite.png"
import tailwind from "../../images/tailwind.png"
import mongo from "../../images/mongo.png"

// links
import github from "../../images/github.svg"
import boxArrow from "../../images/box-arrow-up-right.svg"
import misettaDemo from "../../images/misetta-demo2.png"
const Misetta = () => {
  return (
    <div className="project-two">
      <div className="proj-left">
        {" "}
        <div className="title-card">
          <a href="https://misetta.com/" target="_blank">
            <h1 id="fridgeTitle">Misetta</h1>
          </a>

          <div className="app-links" id="socials">
            {" "}
            <a href="https://misetta.com/" target="_blank">
              <img src={boxArrow} alt="" />
            </a>
          </div>
        </div>
        <div className="description">
          <p className="tagline">
            {" "}
            Social discussion app for sharing, improving, and discovering ideas.
          </p>
          <p>
            It allows you to share ideas, get feedback, add explanations,
            sources, and other structured content. Designed by{" "}
            <a href="https://www.dylancooper.design/" target="_blank">
              Dylan Cooper
            </a>{" "}
            and engineered by me.
          </p>
        </div>
        <div className="description stack">
          <ul>
            <li>
              <img src={react} alt="" />
            </li>
            <li>
              <img src={node} alt="" />
            </li>

            <li>
              <img src={ts} alt="" />
            </li>

            <li>
              <img src={mongo} alt="" />
            </li>
            <li>
              <img src={vite} alt="" />
            </li>
            <li>
              <img src={tailwind} alt="" />
            </li>
          </ul>
        </div>
      </div>

      <div className="proj-right">
        <img src={misettaDemo} alt="" className="" />
      </div>
    </div>
  )
}

export default Misetta
