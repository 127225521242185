import { Link } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import Layout from "../components/Layout"
import github from "../images/github.svg"
import linkedin from "../images/linkedin.svg"
import L from "../images/caret-left.svg"
import R from "../images/caret-right.svg"
import email from "../images/mail.png"
import { SEO } from "../components/seo"
import About from "../components/bio"
import Stack from "../components/stack"
import Fridge from "./portfolioV2/Fridge"
import ReccenterApp from "./portfolioV2/Reccenter"
import Misetta from "./portfolioV2/misetta"
import Contact from "../components/contact"

const Home = () => {
  const [project, setProject] = useState(1)
  const prtf = useRef(null)

  useEffect(() => {
    setTimeout(() => {
      if (prtf.current) {
        // Scroll to the specific ref
        prtf.current.scrollIntoView({ behavior: "smooth" })
      }
    }, 2000)
  }, [])

  return (
    <Layout>
      <section className="hero" id="hero">
        <div>
          <h2 id="myname">Eli Ferster</h2>
          <h1>Full-Stack Developer</h1>
        </div>
        <div className="heroRight">
          <Stack />
        </div>
      </section>
      <section
        className="portfolio-projects"
        id="portfolio-projects"
        ref={prtf}
      >
        <div className="about column">
          <div className="project-title">
            <h3>
              Project
              <span> # {project}</span>
            </h3>

            <div className="socials" id="socials">
              <a
                target="_blank"
                href="https://www.linkedin.com/in/eli-ferster-5a7238141/"
              >
                <img src={linkedin} alt="" />
              </a>
              <a href="https://github.com/glumslug" target="_blank">
                <img src={github} alt="" />
              </a>
              <a href="mailto:eli.c.ferster@gmail.com" target="_blank">
                <img src={email} alt="" id="email" />
              </a>
            </div>
          </div>

          {project === 1 ? (
            <Fridge />
          ) : project === 2 ? (
            <ReccenterApp />
          ) : project === 3 ? (
            <Misetta />
          ) : null}

          <div className="LR">
            <div className="console">
              <img
                src={L}
                alt=""
                onClick={() => {
                  project > 1 ? setProject(project - 1) : setProject(3)
                  // !manual && setManual(true)
                }}
              />
              <img
                src={R}
                alt=""
                onClick={() => {
                  project < 3 ? setProject(project + 1) : setProject(1)
                  // !manual && setManual(true)
                }}
              />
            </div>
          </div>
        </div>
      </section>

      <section id="bio">
        <About />
      </section>
      <section id="contact">
        <Contact />
      </section>
    </Layout>
  )
}

export default Home

export const Head = () => <SEO />
