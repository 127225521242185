import React from "react"
import react from "../images/react.png"
import node from "../images/node.png"
import mongo from "../images/mongo.png"
import socket from "../images/socketio.svg"
import s3 from "../images/s3.png"
import sass from "../images/sass.png"
import gatsby from "../images/gatsby.svg"
import ts from "../images/ts.png"
import django from "../images/django.png"
import mysql from "../images/mysql.png"
import postgres from "../images/postgres.png"
import vite from "../images/vite.png"
import bootstrap from "../images/bootstrap.png"
import tailwind from "../images/tailwind.png"
import d3 from "../images/d3.png"
import ec2 from "../images/ec2.png"

export default function About() {
  return (
    <section>
      <div className="bio">
        <div className="bio-grid">
          <div className="left">
            <h1>Bio</h1>
            <div className="left-sub">
              {" "}
              <p>
                Hello, my name is Eli and I am a{" "}
                <span style={{ color: "#58b4f1" }}>
                  full-stack web developer
                </span>
                .
              </p>
              <p>
                I love making{" "}
                <span style={{ color: "#58b4f1" }}>
                  useful, functionality-packed sites
                </span>{" "}
                that solve problems for people. It constantly amazes me how
                empowering the web can be. I love creating functionality and
                imagining how my users might interact with it.
              </p>
              <p className="not-mobile">
                I also love learning new web technologies. The web technology
                ecosystem is vast and empowering. Being able to{" "}
                <span style={{ color: "#58b4f1" }}>
                  quickly learn and integrate new technologies
                </span>{" "}
                into my projects has become a key focus of mine.
              </p>
            </div>
          </div>
          <div className="right">
            <h1 id="stack">Stack</h1>
            <div className="right-sub">
              <div className="slide-container">
                {" "}
                <ul>
                  <li>
                    <img src={react} alt="" />
                  </li>
                  <li>
                    <img src={node} alt="" />
                  </li>
                  <li>
                    <img src={vite} alt="" />
                  </li>
                  <li>
                    <img src={ts} alt="" />
                  </li>
                  <li>
                    <img src={django} alt="" />
                  </li>
                  <li>
                    <img src={postgres} alt="" />
                  </li>
                  <li>
                    <img
                      src={mysql}
                      alt=""
                      style={{ transform: "translateX(-15px)" }}
                    />
                  </li>

                  <li>
                    <img src={mongo} style={{ paddingLeft: "12px" }} alt="" />
                  </li>
                  <li>
                    <img
                      src={gatsby}
                      style={{ height: "2.6rem", padding: "2px 3px" }}
                      alt=""
                    />
                  </li>

                  <li>
                    <img src={socket} alt="" />
                  </li>
                  <li>
                    <img src={ec2} alt="" />
                  </li>
                  <li>
                    <img src={s3} style={{ padding: "0 4px" }} alt="" />
                  </li>

                  <li>
                    <img src={d3} alt="" />
                  </li>
                  <li>
                    <img src={sass} alt="" />
                  </li>
                  <li>
                    <img src={tailwind} alt="" />
                  </li>
                  <li>
                    <img
                      src={bootstrap}
                      style={{ height: "2.2rem", padding: "6px 0" }}
                      alt=""
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
