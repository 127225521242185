import { Link, graphql, useStaticQuery } from "gatsby"
import React, { useEffect } from "react"

const Navbar = () => {
  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])
  const handleResize = () => {
    if (window.innerWidth > 599) {
      document.getElementById("toggle").checked = false
    }
  }

  return (
    <>
      <nav className="navbar">
        <Link
          className="site-title"
          to="/#hero"
          onClick={() => (document.getElementById("toggle").checked = false)}
        >
          Eli Ferster
        </Link>

        <input type="checkbox" id="toggle" />
        <label className="toggle-button" htmlFor="toggle">
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </label>
        <div className="links">
          <Link
            to="/#portfolio-projects"
            onClick={() => (document.getElementById("toggle").checked = false)}
          >
            Portfolio
          </Link>

          <Link
            to="/#bio"
            onClick={() => (document.getElementById("toggle").checked = false)}
          >
            Bio
          </Link>
          <Link
            to="/#contact"
            onClick={() => (document.getElementById("toggle").checked = false)}
          >
            Contact
          </Link>
          <a
            href="/Eli Ferster Resume - Full Stack Developer.pdf"
            target="_blank"
          >
            Resume
          </a>
        </div>
      </nav>
    </>
  )
}

export default Navbar
