import React from "react"
import Layout from "./Layout"
import { Link } from "gatsby"
import github from "../images/github.svg"
import linkedin from "../images/linkedin.svg"
import react from "../images/react.png"
import node from "../images/node.png"
import mongo from "../images/mongo.png"
import socket from "../images/socketio.svg"
import s3 from "../images/s3.png"
import css from "../images/css.png"
import sass from "../images/sass.png"
import html from "../images/html.png"
import js from "../images/js.png"
import gatsby from "../images/gatsby.svg"
import python from "../images/python.png"
import ts from "../images/ts.png"
import django from "../images/django.png"
import mysql from "../images/mysql.png"
import postgres from "../images/postgres.png"
import vite from "../images/vite.png"
import bootstrap from "../images/bootstrap.png"
import tailwind from "../images/tailwind.png"
import aws from "../images/aws-logo.png"

export default function Stack() {
  return (
    <div className="stack home">
      <ul>
        <li className="animate">
          <img src={react} alt="" />
        </li>
        <li className="animate">
          <img src={node} alt="" />
        </li>
        <li className="animate">
          <img src={mongo} style={{ padding: "0 12px" }} alt="" />
        </li>
        {/* <li>
          <img src={socket} alt="" />
        </li> */}
        {/* <li>
          <img src={s3} style={{ padding: "0 4px" }} alt="" />
        </li> */}
        {/* <li>
          <img src={sass} alt="" />
        </li> */}
        {/* <li>
          <img
            src={gatsby}
            style={{ height: "2.6rem", padding: "2px 3px" }}
            alt=""
          />
        </li> */}
        <li className="animate hide-sm">
          <img src={ts} alt="" />
        </li>
        {/* <li>
          <img src={python} alt="" />
        </li> */}
        {/* <li>
          <img src={postgres} alt="" />
        </li> */}
        <li className="animate">
          <img src={mysql} alt="" />
        </li>
        {/* <li>
          <img src={vite} alt="" />
        </li> */}
        {/* <li>
          <img src={tailwind} alt="" />
        </li> */}
        <li className="animate">
          <img src={aws} alt="" />
        </li>
        {/* <li>
          <img
            src={bootstrap}
            style={{ height: "2.2rem", padding: "6px 0" }}
            alt=""
          />
        </li> */}
      </ul>
    </div>
  )
}
