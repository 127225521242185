import React from "react"
import { Link } from "gatsby"

import react from "../../images/react.png"
import node from "../../images/node.png"
import mongo from "../../images/mongo.png"
import socket from "../../images/socketio.svg"
import s3 from "../../images/s3.png"
import sass from "../../images/sass.png"
import github from "../../images/github.svg"
import boxArrow from "../../images/box-arrow-up-right.svg"

import reccDemo1 from "../../images/recc-basic.png"

const ReccenterApp = () => {
  return (
    <div className="project-two">
      <div className="proj-left">
        {" "}
        <div className="title-card">
          <a href="https://reccenter.netlify.app/" target="_blank">
            <h1>Rec Center</h1>
          </a>

          <div className="app-links" id="socials">
            {" "}
            <a href="https://github.com/glumslug/reccenter" target="_blank">
              <img src={github} alt="" />
            </a>
            <a href="https://reccenter.netlify.app/" target="_blank">
              <img src={boxArrow} alt="" />
            </a>
          </div>
        </div>
        <div className="description">
          <p className="tagline">
            {" "}
            A social-scheduling app for pick-up basketball.
          </p>
          <p>
            The app allows you to set your availability via a schedule, and then
            automatically aggregates your availability with your friends'
            availability for each group you join.
          </p>
        </div>
        <div className="description stack">
          <ul>
            <li>
              <img src={react} alt="" />
            </li>
            <li>
              <img src={node} alt="" />
            </li>
            <li>
              <img src={mongo} style={{ padding: "0 12px" }} alt="" />
            </li>
            <li>
              <img src={socket} alt="" />
            </li>
            <li>
              <img src={s3} style={{ padding: "0 4px" }} alt="" />
            </li>
            <li>
              <img src={sass} alt="" />
            </li>
          </ul>
        </div>
      </div>
      <div className="proj-right">
        <img src={reccDemo1} alt="" className="half" />
      </div>
    </div>
  )
}

export default ReccenterApp
