import React from "react"
import github from "../images/github.svg"
import linkedin from "../images/linkedin.svg"
import email from "../images/mail.png"
const Contact = () => {
  return (
    <div className="about">
      <h1>Contact</h1>
      <h2>Feel free to reach me at any of the below means</h2>
      <div className="socials" id="socials">
        <a
          target="_blank"
          href="https://www.linkedin.com/in/eli-ferster-5a7238141/"
        >
          <img src={linkedin} alt="" />
        </a>
        <a href="https://github.com/glumslug" target="_blank">
          <img src={github} alt="" />
        </a>
        <a href="mailto:eli.c.ferster@gmail.com" target="_blank">
          <img src={email} alt="" id="email" />
        </a>
      </div>
    </div>
  )
}

export default Contact
